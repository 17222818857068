import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { Form, Button, Col, Container, Modal, Row, Tab, Nav } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import AccountDetailModel from "../../models/Account/AccountDetailModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faSave } from "@fortawesome/free-solid-svg-icons";
import AccountDetail from "./accountDetail";
import AccountAditional from "./accountAdtitional";
import AccountPayment from "./accountPayment";
import AccountMedia from "./accountMedia";
import AccountPlan from "./accountPlan";
import EventKey from "react-bootstrap"
import AccountNotification from "./accountNotification";

const AccountPage = () => {
    const navigate = useNavigate();
    const [queryParameters] = useSearchParams();
    const isNullParams =  queryParameters.get('noti');
    const [formValidateAccount, setFormValidateAccount] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState(""); 
    let {getJSON} = useContext(GlobalContext);
    const [accountDetail, setAccountDetail] = useState<AccountDetailModel>(new AccountDetailModel());
    const [handleModal, setHandleModal] = useState(false);
    const [key, setKey] = useState< string | number >('Detalle');
    const getUserData = () => {
        let params = {
            "token_web": Cookies.get("token")
        }
        getJSON("provider", "accountDetail", params).then((response : any) => {
            if(response.status){
                setAccountDetail({...response.data});
                Cookies.set("id",accountDetail.id.toString())
            }
            else{
                Cookies.remove('name');
                Cookies.remove('providerName');
                Cookies.remove('token');
                navigate("/login");
            }

            
        });

        
    }
    const validateAccount = (verifiedAccountDetail: AccountDetailModel) => {
        if(
            !verifiedAccountDetail.curp || 
            !verifiedAccountDetail.second_contact_email || 
            !verifiedAccountDetail.second_contact_name ||
            verifiedAccountDetail.curp.trim() === "" ||
            verifiedAccountDetail.second_contact_email.trim() === "" ||
            verifiedAccountDetail.second_contact_name.trim() === "" 
        ){
            navigate("/account_information");

        }
    }

    const sendData = async ( e:any) => {
        e.preventDefault();

        let data = {
            token_web: Cookies.get("token"),
            detail: accountDetail
        }
        await getJSON("provider", "updateData", data).then((response: any) => {
        if (response.status) {
            setEditMode(false);
        } else {
            //navigate("/login");
            //setError(response.error)
        }
        });
    }

    useEffect( () => {
        if(Cookies.get("name") === "" || Cookies.get("name") === undefined){
            navigate("/login");
        }else{
            getUserData();
        }
    }, [Cookies.get("name")]);

    useEffect(() => {
        if(accountDetail.id){
            validateAccount(accountDetail);
        }
    }, [accountDetail])

    useEffect(()=> {

        document.title = "Home Fiesta - Mi Cuenta"
    });

    useEffect(() => {
        
        console.log(isNullParams);
        if(isNullParams){
            setHandleModal(true);
        }
    }, [isNullParams])

    useEffect(() => {
        console.log(key);
    }, [key])

    return(
        <Container>
            <Row >
                <Col xs={8}>
                    <h2 style={{color: 'rgb(241, 46, 149)'}}>
                        Mi Cuenta - { accountDetail.name }
                    </h2>
                </Col>
                <Col xs={4} style={{display: 'flex', justifyContent: 'end', columnGap: '10px'}}>
                {key === 'Detalle' || key === 'Informacion' && (
                    <>
                    
                        <Link to={"/provider/" + accountDetail.id + "/true"} target="_blank" className="button-editAccount btn btn-primary" > 
                            <span>Ver en el sitio <FontAwesomeIcon icon={faEye} /></span>
                        </Link>
                        <Button className="button-editAccount" > {!editMode && (
                            <span onClick={() => {
                                setEditMode(!editMode)
                            }}>Editar <FontAwesomeIcon icon={faEdit} /></span>
                        )}{editMode && (
                            <span onClick={sendData}>Guardar <FontAwesomeIcon icon={faSave} /></span>
                        )} </Button>
                    </>
                )}
                </Col>
                <Col md={12}>
                    {error.trim() !== '' && (<p className="error-data">
                        {error}
                    </p>)}
                </Col>
            </Row>
            <Row style={{marginTop: '15px'}}>
                <Tab.Container activeKey={key}
                    onSelect={(k) => setKey(k!!)} id="left-tabs-example" defaultActiveKey="Detalle">
                    <Row>
                        <Col sm={2} xs={12}>
                            <Nav  variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="Detalle">Detalle</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Informacion">Información adicional</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Notificaciones">Notificaciones</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Multimedia">Multimedia</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Plan">Mi Plan</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Pagos">Historial de Pagos</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Eventos">Mis Eventos</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={10} xs={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="Detalle">
                                <AccountDetail currentKey={key} setAccountDetail={setAccountDetail} accountDetail={accountDetail} editMode={editMode} />
                            </Tab.Pane>
                            <Tab.Pane  eventKey="Informacion">
                                <AccountAditional currentKey={key} setAccountDetail={setAccountDetail} accountDetail={accountDetail} editMode={editMode} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Notificaciones">
                                <AccountNotification currentKey={key} idProvider={accountDetail.id} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Multimedia">
                                <AccountMedia currentKey={key} setAccountDetail={setAccountDetail} accountDetail={accountDetail} setKey={setKey}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Pagos">
                                <AccountPayment currentKey={key} idProvider={accountDetail.id} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Plan">
                                <AccountPlan currentKey={key} currentPlan={accountDetail.plan} idProvider={accountDetail.id} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Eventos">Mis Eventos</Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Row>

            <Modal
                show={handleModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {queryParameters.get('noti') === 'free' &&
                            <span>Bienvenido a Home Fiesta</span>
                        }

                        {queryParameters.get('noti') === 'pending' &&
                            <span>Tu pago se ha enviado</span>
                        }
                        
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {queryParameters.get('noti') === 'free' &&
                        <>
                            Ya eres parte de HomeFiesta, ahora tienes acceso a la plataforma, recuerda que la versión gratuita contiene funciones limitadas, en caso de que quieras acceder a todas las funcionalidades puedes acceder a uno de nuestros planes de pago. <br/> <b style={{color: '#F14E95'}}>
                                
                                Antes de poder subir tu información multimedia, dirigete a la sección de Información Adicional
                                </b>
                        </>
                    }
                    {queryParameters.get('noti') === 'pending' &&
                        <>
                            Tu pago ha sido enviado, en estos momentos se encuentra pendiente de revisión, una vez que tus documentos sean verificados, te notificaremos vía correo electrónico. <br/>
                            <b style={{color: '#F14E95'}}>
                                
                                Antes de poder subir tu información multimedia, dirigete a la sección de Información Adicional
                                </b> <br/>

                            <span className="brand-team">Atentamente, HomeFiesta</span>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setHandleModal(!handleModal)}>¡Entendido!</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default AccountPage;