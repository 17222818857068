import { Col, Container, Row, Image, Button, Alert} from "react-bootstrap"
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import platform from 'platform';
import Cookies from "js-cookie";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import GoogleMapReact from 'google-map-react';
import React, { useContext, useEffect } from "react";
import ProviderDetailModel from "../../models/ProviderDetailModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faHeartCircleMinus, faHeartCirclePlus, faLink, faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";
import image1 from '../../assets/providers/slide_1.webp';
import { Link, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { ProviderImage } from "../../models/ProviderModel";
import { faFacebook, faInstagram, faInstagramSquare, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";

const AnyReactComponent = (text: any ) => <div><FontAwesomeIcon  className="marker" size='3x' icon={faMapMarkerAlt} /><p className="text-marker">{text.text}</p></div>;
export const ProviderPage = () =>{
    const id = useParams()["id"];
    const isPreview = Boolean(useParams()["isPreview"]);
    const [provider,setProvider] = React.useState<ProviderDetailModel>();
    const [provImages,setProviderImages] = React.useState<ProviderImage[]>([]);
    const [provServices,setProviderServices] = React.useState<{name:string, image: string}[]>([]);
    const [categories,setCategories] = React.useState<any[]>([]);
    let {getJSON} = useContext(GlobalContext);
    const [center,setCenter] = React.useState<{lat: string,lng: string}>({lat: '0.00', lng: '0.00'});
    const [flagWishList, setFlagWish] = React.useState(true);
    const [flagWishLink, setFlagWishLink] = React.useState(false);


    const getProvider = async() => {
        let params = {
            'id': id,
            'ua': platform.ua,
            'isPreview': isPreview
        }
        await getJSON("provider", "getById", params).then((response: any) => {
            if(response.status){
                setProvider(response.data);
                let coordinates = response.data.coordinates.split(",");
                setCenter({lat: coordinates[0], lng: coordinates[1]});
            }

        });
    }

    const getImages = async() => {
        let params = {
            'id': id
        }
        await getJSON("provider", "getImages", params).then((response: any) => {
            if(response.status){
                setProviderImages(response.data);
            }
        });
    }

    const getServices = async() => {
        let params = {
            'id': id
        }
        await getJSON("provider", "getServices", params).then((response: any) => {
            if(response.status){
                setProviderServices(response.data);
            }
        });
    }

    const getCategories = async () => {
        let params = {

        }
        await  getJSON("category", "getAllByFeatured", params).then((response: any) => {
            if(response.status){
                setCategories(response.data);
            }

        });
    }

    const addWishList = () =>{
        let wishlist;

        try {
            wishlist = JSON.parse(Cookies.get("whishlist")!!);
        } catch (error) {
            console.error("Error parsing wishlist:", error);
            wishlist = [];
        }

        if (!wishlist.includes(parseInt(id!!, 10))) {
            wishlist.push(id !== undefined ? parseInt(id, 10) : '');
            Cookies.set("whishlist", JSON.stringify(wishlist));

            setFlagWish(false);
            setFlagWishLink(true);
        }
    }

    const deleteFromWishList = () => {
        let providers = [];
        if(Cookies.get("whishlist") !== undefined){
            const currentProviders = JSON.parse(Cookies.get("whishlist")!! );
            providers = currentProviders.filter( (x:number ) => x !== parseInt(id!!));
            Cookies.set('whishlist', JSON.stringify(providers));
        }

        setFlagWish(true);
        handleShowWishLink();
    }

    const handleShowWishLink = () => {
        let wishlist;

        try {
            wishlist = JSON.parse(Cookies.get("whishlist")!!);
        } catch (error) {
            console.error("Error parsing wishlist:", error);
            wishlist = [];
        }

        setFlagWishLink(wishlist.length == 0 ? false : true);
    }

    const providerStyles = {
        rowText2:{
            paddingTop: '1.3em',
            color: '#F12E95',
            fontSize: '2.2em',
            fontWeight: 700,
            textAling: 'center'
        }
    }

    useEffect(() => {
        if (provider?.id === 0 || provider?.id == undefined || provider.id == null){
            getProvider();
            getImages();
            getServices();
            getCategories();
            handleShowWishLink();
        }

        let wishlist;
        try {
            wishlist = JSON.parse(Cookies.get("whishlist")!!);
        } catch (error) {
            wishlist = [];
        }

        if (wishlist.includes(parseInt(id!!, 10))) {
            setFlagWish(false);
        }
       
        window.scrollTo(0, 0);
    },[id]);

    useEffect(() =>{
        document.title = "Home Fiesta - " + provider?.name
        console.log(isPreview);
    },[provider])
    
    return(
        <Container>
            {isPreview && (<Row>
                <Alert variant='warning'>
                    Esta pantalla es una vista previa de como se verá tu información en HomeFiesta <br/>
                    Una vez que agregues más información, esta vista previa se actualizará.
                </Alert>
            </Row>)}
            <Row>
                <Col lg={8} xs={12}>
                    <Row lg={1}>
                        <Col>
                            <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            navigation={true}
                            className="swiper-providers"
                            modules={[Navigation]}
                            >
                            {provImages.length > 0 && provImages.map((provImage: ProviderImage, index:number) => {
                                    return <SwiperSlide key={index} className="swiper-images-provider">
                                        <Image src={provImage.url} alt={"slide-" + (index + 1)} />
                                </SwiperSlide>
                                })}
                            </Swiper>
                        </Col>
                        {flagWishList && (
                            <Col className="wishlist-button">
                                <Button onClick={addWishList} className="addWishList"><FontAwesomeIcon icon={faHeartCirclePlus} /> &nbsp; Añadir a la lista de deseos</Button>
                                {flagWishLink && (
                                    <Link className="wish-link" to="/wishlist" > Ver Lista de deseos</Link>
                                )}
                            </Col>
                        )}
                        {!flagWishList && (
                            <Col className="wishlist-button">
                                <Button onClick={deleteFromWishList} className="addWishList"><FontAwesomeIcon icon={faHeartCircleMinus} /> &nbsp; Eliminar de la lista de deseos</Button>
                                <Link className="wish-link" to="/wishlist" > Ver Lista de deseos</Link>
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col lg={4} xs={12}>
                    {/* Info Proveedor */}
                    <Row lg={1} xs={1} className="detail-provider">
                        <Col className="title">{provider?.name}</Col>
                        <Col className="from services">{provServices.map((service: {name: string , image: string}, index: number ) =>{ 

                                {/*return <> {service.name} {(index + 1) !== provServices.length && (",")}  
                                </>  ultima modifcacion con valores del texto del servicio*/}
                                { if (service.image !== null  && service.image !== undefined) {
                                    return <>
                                        <img src={service.image} key={index} className="image-ico-provider" alt={service.name}/> 
                                    </> 
                                }
                                    else{
                                        return <></>
                                    }
                                }
                                 
                            })}
                        </Col>
                        <Col className="from"><FontAwesomeIcon icon={faCoins} />&nbsp; Desde: ${provider?.min_amount !== undefined ? provider?.min_amount : 99.00} MXN</Col>
                        <Col className="from"><FontAwesomeIcon icon={faCoins} />&nbsp; Hasta: ${provider?.max_amount !== undefined ? provider?.max_amount : 99.00} MXN</Col>
                    </Row>
                    <Row lg={1} xs={2} style={{paddingTop: '30px'}} className="detail-provider">
                        <Col className="website"><FontAwesomeIcon icon={faLink} />&nbsp;<a href={provider?.website} target="_blank">{provider?.website}</a></Col>
                        {provider?.facebook && (
                            <Col className="website"><FontAwesomeIcon icon={faFacebook} />&nbsp;<a href={provider?.facebook} target="_blank">Facebook</a></Col>
                        )}
                        {provider?.instagram && (
                            <Col className="website"><FontAwesomeIcon icon={faInstagram} />&nbsp;<a href={provider?.instagram} target="_blank">Instagram</a></Col>
                        )}
                        {provider?.tiktok && (
                            <Col className="website"><FontAwesomeIcon icon={faTiktok} />&nbsp;<a href={provider?.tiktok} target="_blank">TikTok</a></Col>
                        )}
                        {provider?.youtube && (
                            <Col className="website"><FontAwesomeIcon icon={faYoutube} />&nbsp;<a href={provider?.youtube} target="_blank">Yotube</a></Col>
                        )}
                        
                        <Col className="phone"><FontAwesomeIcon icon={faPhone} />&nbsp;<a href={"tel:" + provider?.phone} target="_blank">{provider?.phone}</a></Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{marginTop: '1.2em'}}>
                <Col lg={12}>
                    <Col className="info"> <div dangerouslySetInnerHTML={{__html: provider !== undefined ? provider.description : ''}}></div></Col>
                </Col>
                <Col lg={12}>
                    <div style={{position: "relative"}}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyBJywWDNp2KQiWH6yoUbDR4VX0OFHk_24I"}}
                            center={{lat: parseFloat(center.lat), lng: parseFloat(center.lng) }}
                            zoom={13}
                            yesIWantToUseGoogleMapApiInternals
                            style= {{height: "450px", width: "100%", display: "block"}}
                            draggable={true}
                        >
                            <AnyReactComponent
                                lat={parseFloat(center.lat)}
                                lng={parseFloat(center.lng)}
                                className="marker"
                                draggable={true}
                                text={provider?.name}
                            />
                        </GoogleMapReact>
                    </div>
                </Col>
            </Row>
            <Row style={providerStyles.rowText2}>
                <Col lg={12} style={{textAlign: 'center'}}>Podría Interesarte <br/></Col>
                <Col lg={12} className="swiper-providers-container">
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={6}
                        navigation={true}
                        className="swiper-home-providers"
                        breakpoints={{
                            400:{
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 6,
                                spaceBetween: 20,
                            },
                        }}
                        modules={[Navigation]}
                        >
                           {categories.map((category:any, index:number) => {
                            const url = category.image_url === null ? 'https://api.vivalasfiestas.com/modules/admin/categoria/default_category.png' : category.image_url;
                                return <SwiperSlide key={index} className="swiper-content-providers">
                                <Link to={"/events/" + category.id }>
                                    <Image src={url} alt={category.name} />
                                    <span className="title" style={{justifyContent: 'center', textAlign: 'center'}}>{category.name}</span>
                                    
                                </Link>
                            </SwiperSlide>
                            })}
                    </Swiper>
                </Col>
            </Row>
        </Container>
    )
}