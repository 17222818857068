import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Toast,
  Modal,
  ModalFooter,
} from "react-bootstrap";
import AccountDetailModel from "../../models/Account/AccountDetailModel";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface AccountInformationPageProps {
  currentKey: string | number;
  accountDetail: AccountDetailModel;
  setAccountDetail: (value: AccountDetailModel) => void;
  setKey: (value: string | number) => void;
}

const AccountMedia: React.FC<AccountInformationPageProps> = ({
  currentKey,
  accountDetail,
  setAccountDetail,
  setKey,
}) => {
  const plans = {
    F: { images: 1, videos: 0 },
    CB: { images: 3, videos: 0 },
    CM: { images: 3, videos: 1 },
    CS: { images: 3, videos: 2 },
    CO: { images: 3, videos: 3 },
    CP: { images: 3, videos: 3 },
  };
  const [limit, setLimit] = useState<{ images: number; videos: number }>({
    images: 0,
    videos: 0,
  });
  const [planLimit, setPlanLimit] = useState<{
    images: number;
    videos: number;
  }>({ images: 0, videos: 0 });
  const [countMedia, setCountMedia] = useState<{
    images: number;
    videos: number;
  }>({ images: 0, videos: 0 });
  const [showToast, setShowToast] = useState(true);
  const [file, setFile] = useState<File | undefined>();
  const [isLoading, setLoading] = useState(false);
  const [admitedFiles, setAdmitedFiles] = useState("image/*,video/*");
  let { getJSON, sendData } = useContext(GlobalContext);
  const [uploaded, setUploaded] = useState(false);
  const [handleModal, setHandleModal] = useState(false);
  const [handleModalInformation, setHandleModalInformation] = useState(true);

  const getMedia = (idProvider: string) => {
    let params = {
      idProvider: idProvider,
    };
    getJSON("provider", "getAccountMedia", params).then((response: any) => {
      if (response.status) {
        setAccountDetail({
          ...accountDetail,
          links_media: response.data,
        });
        countFiles(response.data);
      }
    });
  };

  const handleOnChangeFile = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement & {
      files: FileList;
    };
    let file = target.files?.[0];
    if (file == undefined) return;

    if (file.type.startsWith("video/")) {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        if (video.duration > 10) {
          alert(
            "La duración del video no puede superar los 10 segundos, intenta de nuevo"
          );
          target.value = "";
        } else {
          setFile(file);
        }
      };

      video.src = URL.createObjectURL(file);
    } else {
      setFile(file);
    }
  };

  const countFiles = (data: []) => {
    let countImage = 0;
    let countVideo = 0;
    data.forEach((item: any) => {
      if (item.type == "Image") {
        countImage++;
      } else if (item.type == "Video") {
        countVideo++;
      }
    });

    setCountMedia({ images: countImage, videos: countVideo });
  };

  const getFileExtension = (fileName: string): string | null | undefined => {
    const parts = fileName.split(".");
    return parts.length > 1 ? parts.pop() : null;
  };

  const submitFile = () => {
    if (typeof file === "undefined") return;

    if (
      (limit.images !== 0 && isImageOrVideo(file!) == "Image") ||
      (limit.videos !== 0 && isImageOrVideo(file!) === "Video")
    ) {
      const formData: FormData = new FormData(undefined);
      const idProvider = accountDetail.id.toString();

      formData.append("file", file);
      formData.append("type", isImageOrVideo(file));
      formData.append("idProvider", idProvider);

      sendData("provider", "uploadMedia", formData, undefined, "formData").then(
        (response: any) => {
          if (response.status) {
            setUploaded(true);
            getMedia(idProvider);
          } else {
            console.log(response);
          }
        }
      );
    } else {
      alert(
        "Lo sentimos, has alcanzado el limite de archivos multimedia para tu plan actual"
      );
    }
  };

  const deleteMedia = (id: number) => {
    let params = {
      id: id,
    };
    getJSON("provider", "deleteMedia", params).then((response: any) => {
      if (response.status) {
        const idProvider = accountDetail.id.toString();
        getMedia(idProvider);
      }
    });
  };

  const isImageOrVideo = (file: File): "Image" | "Video" | "unknown" => {
    const mimeType = file.type;

    if (mimeType.startsWith("image/")) {
      return "Image";
    } else if (mimeType.startsWith("video/")) {
      return "Video";
    } else {
      return "unknown";
    }
  };

  const saveAcceptMediaTerms = async () => {
      let data = {
        idProvider: accountDetail.id,
        accepted_media_terms: 1
      }
      await getJSON("provider", "updateMediaTerms", data ).then(
        (response: any) => {
          if (response.status) {
            setHandleModal(false);
          } else{
            setHandleModal(true);
          }
        }
      );
  };
   

  const recalc = () => {
    let newLimitImages = planLimit.images - countMedia.images;
    let newLimitVideos = planLimit.videos - countMedia.videos;

    setLimit({ images: newLimitImages, videos: newLimitVideos });
  };

  const toInformation = () => {
    setKey("Informacion");
    setHandleModalInformation(false);
  };

  useEffect( () => {
    if (accountDetail.id > 0 && limit.images === 0 && limit.videos === 0) {
      var current = plans[accountDetail.plan];
      setLimit(current);
      setPlanLimit(current);
      countFiles(accountDetail.links_media);
      recalc();
    }
  }, [accountDetail]);

  useEffect(() => {
    if (
      accountDetail.id &&
      accountDetail.accepted_media_terms == 0 &&
      currentKey == "Multimedia"
    ) {
      setHandleModal(true);
    }

    if (
       accountDetail.max_amount == 0.00 || accountDetail.min_amount == 0.00 &&
      (accountDetail.id !== null && currentKey == "Multimedia")
    ) {
      setHandleModalInformation(true);
    }
  }, [currentKey]);

  useEffect(() => {
    recalc();
  }, [countMedia]);

  useEffect(() => {
    if (currentKey !== "Multimedia") {
      setHandleModalInformation(false);
    }
  }, [currentKey]);

  return (
    <>
      <Row className="info-multimedia">
        <Col md={4}>
          {" "}
          <span>Archivos multimedia</span>
        </Col>
        <Col md={8}>
          {" "}
          <span>
            Aún puedes subir:{" "}
            {limit.images == 1
              ? limit.images + " imagen"
              : limit.images + " imágenes"}{" "}
            y{" "}
            {limit.videos == 1
              ? limit.videos + " video"
              : limit.videos + " videos"}{" "}
          </span>
        </Col>
      </Row>
      <Row style={{ padding: "2em" }} md={1}>
        <Col className="uploadButton">
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              rowGap: "0.8em",
            }}
          >
            <input
              id="image"
              type="file"
              name="image"
              accept={admitedFiles}
              onChange={handleOnChangeFile}
            />
            <Button
              onClick={submitFile}
              disabled={isLoading}
              style={{
                backgroundColor: "rgb(241, 46, 149)",
                maxWidth: "200px",
              }}
            >
              <Spinner
                className={isLoading ? "spinner-active" : "spinner-not-active"}
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              {isLoading ? "Subiendo..." : "Subir"}
            </Button>
          </Form>
        </Col>
        <Col>
          <center style={{ paddingTop: "1em" }}>
            <span style={{ color: "rgb(241, 46, 149)" }}>
              {" "}
              Tamaño recomendado: 1920 x 1080 px con un peso máximo de 5MB
            </span>
          </center>
        </Col>
      </Row>
      <Row className="info-multimedia">
        <span>Tu Multimedia:</span>
      </Row>
      <Row md={4} xs={2}>
        {accountDetail.links_media.map(
          (
            media: {
              id: number;
              item_order: number;
              type: string;
              url: string;
              status: number;
            },
            index: number
          ) => (
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                rowGap: "0.8em",
              }}
              key={index + "media"}
            >
              <>
                {media.type === "Image" && (
                  <>
                    <span style={{ color: "#F14E95", fontWeight: "600" }}>
                      Imagen
                    </span>
                    <img
                      className="media-image"
                      src={accountDetail.path + media.url}
                      style={{ maxWidth: "100%", borderRadius: "7%" }}
                    />
                    <span style={{ color: "#F14E95", fontWeight: "600" }}>
                      {media.status == 0
                        ? "Pendiente"
                        : media.status == 1
                        ? "Aceptado"
                        : "Denegado"}
                    </span>
                  </>
                )}
                {media.type === "Video" && (
                  <>
                    <span style={{ color: "#F14E95", fontWeight: "600" }}>
                      Video
                    </span>
                    <video
                      className="media-video"
                      controls
                      muted
                      style={{ maxWidth: "100%" }}
                    >
                      <source
                        src={accountDetail.path + media.url}
                        type={"video/" + getFileExtension(media.url)}
                      />
                    </video>
                    <span style={{ color: "#F14E95", fontWeight: "600" }}>
                      {media.status == 0
                        ? "Pendiente"
                        : media.status == 1
                        ? "Aceptado"
                        : "Denegado"}
                    </span>
                  </>
                )}
                <Button
                  style={{ fontSize: "0.85em" }}
                  onClick={() => deleteMedia(media.id)}
                >
                  <FontAwesomeIcon icon={faTrash} /> Eliminar
                </Button>
              </>
            </Col>
          )
        )}
      </Row>

      <Modal
        show={handleModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <span>Debes aceptar los términos y condiciones</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center  style={{paddingTop: '1.5em'}}>
            Su información, imágenes y videos serán revisados, como prevención,
            que no violen flagrantemente los términos y condiciones del portal,
            su, información, imágenes y video deberán cumplir en todo momento,
            con el punto 2.4. Obligaciones en la inserción de contenidos en el
            Portal Web El portal se reserva todas las acciones, referidas en
            las, Condiciones de Uso de los portales web, thehomefiesta.com.mx, y
            vivalasfiestas.com, por el uso inadecuado.
          </center>
          <center>
            Por favor acepta los términos y condiciones para poder subir tu
            contenido multmedia
          </center>
          <center style={{paddingBottom: '1.5em'}}>
            <Row>
              <Col className="terms_conditions">
                <Form.Check
                  required
                  name="terms"
                  label="Acepta los"
                  checked={accountDetail.accepted_media_terms == 1 ? true : false}
                  onChange={(event: any) => {
                    setAccountDetail({
                      ...accountDetail,
                      accepted_media_terms: event.target.checked ? 1 : 0,
                    });
                  }}
                  id="validationFormik0"
                />
                <a
                  href="/terms_conditions"
                  className="pink-text"
                  target="_blank"
                >
                  Términos y Condiciones
                </a>
              </Col>
            </Row>
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => saveAcceptMediaTerms()}>¡Acepto los términos!</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={handleModalInformation}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-information"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <span>Complementa tu información</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            Aún no has ingresado tu información adicional, te redigiremos a la
            pestaña correspondiente <br />
            Una vez completada tu información, tendrás acceso al apartado
            multimedia.
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => toInformation()}>¡Entendido!</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AccountMedia;
