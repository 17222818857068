import { Col, Container, Modal, Row } from "react-bootstrap";
import Plans from "../../models/Plan/Plan";
import { useContext, useEffect, useState } from "react";
import getNextMonthDateMexicoFormat, { formatDate } from "../../component/getNextMonthDateMexicoFormat";
import { GlobalContext } from "../../context/GlobalContext";
import dateOnMexicoFormat from "../../component/getNextMonthDateMexicoFormat";

interface AccountInformationPageProps {
  currentKey: string | number;
  idProvider: number;
  currentPlan: string;
}

const AccountPlan: React.FC<AccountInformationPageProps> = ({
  currentKey,
  idProvider,
  currentPlan,
}) => {
  const currentPlanData = Plans.find((plan) => plan.value === currentPlan);
  const [selectedPlan, setSelectedPlan] = useState<{
    price: number;
    value: string;
  }>({ price: 0.0, value: currentPlan });
  let { getJSON } = useContext(GlobalContext);
  const [showPlans, setShowPlans] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [expires, setExpires] = useState('2022-02-20');

  const saveNewPlan = async(e:any) => {
    e.preventDefault();
    if (currentPlan !== selectedPlan.value) {
      let params = {
        idProvider: idProvider,
        plan: selectedPlan.value
      };
      await getJSON("provider", "updatePlan", params).then(
        (response: any) => {
          if (response.status) {
                setShowModal(false);
          }
        }
      );
    }

  };
  const getExpiresTimePlan = async() => {
    if (currentPlan !== selectedPlan.value) {
      let params = {
        idProvider: idProvider
      };
      await getJSON("provider", "GetPlanExpiration", params).then(
        (response: any) => {
          if (response.status) {
            setExpires(response.data[0].expires);
          }
        }
      );
    }

  };

  const handleShowPlan = () => {
    setShowPlans(!showPlans);
  };

  const handleShowModal = (e:any) => {
    e.preventDefault();
    setShowModal(!showModal);
  }

  useEffect(() => {
        setSelectedPlan({price: 0.00, value: currentPlanData?.value!!})
  },[currentPlan])

  useEffect(() => { 
    getExpiresTimePlan();
  },[idProvider]);

  useEffect(() => { 
    console.log('expires', expires)
  },[expires]);
  return (
    <>
      <Container>
        <Row>
          <Col>
            <span className="title-container">Mi Plan Actual:</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="title-container">Vence: {dateOnMexicoFormat(expires) } </span>
          </Col>
        </Row>
        <Row md={1} xs={1} className="plan-container">
          <Col>
            <span className="plan-title">{currentPlanData?.title}</span>
          </Col>
          <Col>
            <span>Precio: ${currentPlanData?.price} MXN</span>
          </Col>
          <Col>
            <span className="plan-description-header">Descripción:</span>
          </Col>
          <Col>
            <div
              dangerouslySetInnerHTML={{
                __html: currentPlanData?.plan_specs!!,
              }}
            ></div>
          </Col>
        </Row>
        <Row md={3} xs={1}>
          <Col></Col>
          <Col className="containerChangeplan">
            <button onClick={() => handleShowPlan()}>
              {showPlans ? "Cancelar" : "Cambiar Plan"}
            </button>
          </Col>
          <Col></Col>
        </Row>
        {showPlans && (
          <>
            <Row md={3} lg={6} xs={2}>
              {Plans.map((plan: any, index: number) => {
                return (
                  <Col
                    key={index + "card-plan"}
                    className={`card-plan ${
                      plan.value == selectedPlan.value ? "selected" : ""
                    }`}
                  >
                    <span className="plan-title">
                      {" "}
                      {plan.title + `(${plan.value})`}
                    </span>
                    <span className="plan-price">
                      {" "}
                      $ {plan.price} MXN / Mes{" "}
                    </span>
                    <div
                      className="plan-especs"
                      dangerouslySetInnerHTML={{ __html: plan.plan_specs }}
                    ></div>
                    <span>
                      <input
                        type="radio"
                        name="plan"
                        value={plan.value}
                        checked={plan.value === selectedPlan.value ? true : false}
                        onChange={(e: any) => {
                          setSelectedPlan({
                            ...selectedPlan,
                            value: e.target.value,
                            price: plan.price,
                          });
                        }}
                      />
                    </span>
                  </Col>
                );
              })}
            </Row>
            <Row>
              <Col></Col>
              <Col className="containerChangeplan">
                <button onClick={handleShowModal}>Guardar</button>
              </Col>
              <Col></Col>
            </Row>
          </>
        )}
        <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
          <Modal.Header closeButton>
            <Modal.Title>Plan seleccionado</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedPlan.value === currentPlan && (
              <>
                Has elegido el mismo plan con el que actualmente cuentas , por
                lo que tu información no se ha actualizado
              </>
            )}
            {selectedPlan.value !== currentPlan && (
              <>
                ¡Gracias!, has cambiado tu plan con HomeFiesta y entrará en
                rigor a partir de tu próxima facturación hasta el dia
                <b>{dateOnMexicoFormat('2020-10-10')}</b> junto con el nuevo monto
                de cobro <br />
                Atte: Home Fiesta
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="button-confirm-plan"
              onClick={saveNewPlan}
            >
              ¡Entendido!
            </button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default AccountPlan;
