

const dateOnMexicoFormat = (date_text:string): string => {
    const date = new Date(date_text);
   
    return date.toLocaleDateString("es-MX", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  export default dateOnMexicoFormat;

  export const formatDate = (date: Date): string => {
    // Crear un formateador para el día, mes y año en español
    const formatter = new Intl.DateTimeFormat('es-MX', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  
    // Formatear la fecha como "15 de febrero de 2025"
    const formattedDate = formatter.format(date);
  
    // Obtener la parte de "2025-02-15" usando toISOString
    const isoDate = date.toISOString().split('T')[0];
  
    return `"${isoDate}" ${formattedDate}`;
  };
